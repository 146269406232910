import "./main.css";
import { jsxs as b, Fragment as L, jsx as r } from "react/jsx-runtime";
import { useState as A, createContext as Y, useContext as Q, useCallback as X, useEffect as Z, useMemo as S, forwardRef as ee, Fragment as te, useId as pe } from "react";
import { Modal as be, Button as x, Popover as ye, FormGroup as ne, ValidatedOptions as I, Select as re, SelectVariant as Ce, SelectOption as le, Switch as Ie, TextInput as ae, TextArea as ve, NumberInput as Te, AlertGroup as Se, Alert as ke, AlertVariant as _, AlertActionCloseButton as xe, InputGroup as se, ButtonVariant as H, Checkbox as Oe, Radio as Ae, Text as Ne, Title as Ve, Card as qe, CardHeader as we, CardTitle as Le, CardBody as Re, Grid as Fe, GridItem as G, PageSection as _e, JumpLinks as De, JumpLinksItem as Pe } from "@patternfly/react-core";
import { useFormContext as E, Controller as N, useController as oe, FormProvider as Ee, useWatch as Me } from "react-hook-form";
import { HelpIcon as j, CubeIcon as $e, PaypalIcon as He, InstagramIcon as Ge, BitbucketIcon as je, MicrosoftIcon as Be, TwitterIcon as Ue, StackOverflowIcon as Je, OpenshiftIcon as We, LinkedinIcon as Ke, GoogleIcon as ze, GitlabIcon as Ye, FacebookSquareIcon as Qe, GithubIcon as Xe, MinusCircleIcon as Ze, PlusCircleIcon as et } from "@patternfly/react-icons";
import { get as B } from "lodash-es";
const Pt = ({
  modalTitle: e,
  continueLabel: t,
  cancelLabel: n,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: s,
  buttonTestRole: o,
  onContinue: c,
  component: u = x,
  children: d,
  ...m
}) => {
  const [g, f] = A(!1);
  return /* @__PURE__ */ b(L, { children: [
    /* @__PURE__ */ r(
      u,
      {
        variant: s,
        onClick: () => f(!0),
        isDisabled: a,
        "data-testrole": o,
        children: l
      }
    ),
    /* @__PURE__ */ r(
      be,
      {
        variant: "small",
        ...m,
        title: e,
        isOpen: g,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), c();
              },
              children: t
            },
            "confirm"
          ),
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: n
            },
            "cancel"
          )
        ],
        children: d
      }
    )
  ] });
};
function tt(e, t) {
  const n = Y(t);
  return n.displayName = e, n;
}
function nt(e) {
  return e != null;
}
function rt(e) {
  const t = Q(e);
  if (nt(t))
    return t;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function lt(e, t, n) {
  const [l, a] = A(
    () => e.getItem(t) ?? n
  ), s = X((o) => {
    a(o), e.setItem(t, o);
  }, []);
  return Z(() => {
    a(e.getItem(t) ?? n), window.addEventListener("storage", o);
    function o(c) {
      c.storageArea === e && (c.key === null || c.key === t) && a(c.newValue ?? n);
    }
    return () => window.removeEventListener("storage", o);
  }, [e, t]), [l, s];
}
function at(e, t, n) {
  const l = S(
    () => JSON.stringify(n),
    [n]
  ), [a, s] = lt(
    e,
    t,
    l
  ), o = S(() => JSON.parse(a), [a]), c = X(
    (u) => s(JSON.stringify(u)),
    []
  );
  return [o, c];
}
const ie = tt(
  "HelpContext",
  void 0
), st = () => rt(ie), Et = ({ children: e }) => {
  const [t, n] = at(localStorage, "helpEnabled", !0);
  function l() {
    n(!t);
  }
  return /* @__PURE__ */ r(ie.Provider, { value: { enabled: t, toggleHelp: l }, children: e });
}, ce = ({
  helpText: e,
  fieldLabelId: t,
  noVerticalAlign: n = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = st();
  return a ? /* @__PURE__ */ r(ye, { bodyContent: e, children: /* @__PURE__ */ b(L, { children: [
    !l && /* @__PURE__ */ r(
      "button",
      {
        "data-testid": `help-label-${t}`,
        "aria-label": t,
        onClick: (s) => s.preventDefault(),
        className: "pf-c-form__group-label-help",
        children: /* @__PURE__ */ r(j, { noVerticalAlign: n })
      }
    ),
    l && /* @__PURE__ */ r(j, { noVerticalAlign: n })
  ] }) }) : null;
}, V = ({
  name: e,
  label: t,
  labelIcon: n,
  error: l,
  children: a,
  ...s
}) => /* @__PURE__ */ r(
  ne,
  {
    label: t || e,
    fieldId: e,
    labelIcon: n ? /* @__PURE__ */ r(ce, { helpText: n, fieldLabelId: e }) : void 0,
    helperTextInvalid: l?.message,
    validated: l ? I.error : I.default,
    ...s,
    children: a
  }
), ot = ({
  name: e,
  label: t,
  options: n,
  controller: l,
  variant: a,
  labelIcon: s,
  ...o
}) => {
  const {
    control: c,
    formState: { errors: u }
  } = E(), [d, m] = A(!1);
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: t,
      isRequired: l.rules?.required === !0,
      error: u[e],
      labelIcon: s,
      children: /* @__PURE__ */ r(
        N,
        {
          ...l,
          name: e,
          control: c,
          render: ({ field: { onChange: g, value: f } }) => /* @__PURE__ */ r(
            re,
            {
              ...o,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (i) => m(i),
              selections: typeof n[0] != "string" ? n.filter((i) => f.includes(i.key)).map((i) => i.value) : f,
              onSelect: (i, h) => {
                if (a === "typeaheadmulti") {
                  const v = h.toString();
                  f.includes(v) ? g(f.filter((k) => k !== v)) : g([...f, v]);
                } else
                  g(h), m(!1);
              },
              onClear: a !== Ce.single ? (i) => {
                i.stopPropagation(), g([]);
              } : void 0,
              isOpen: d,
              variant: a,
              validated: u[e] ? I.error : I.default,
              children: n.map((i) => /* @__PURE__ */ r(
                le,
                {
                  value: typeof i == "string" ? i : i.key,
                  children: typeof i == "string" ? i : i.value
                },
                typeof i == "string" ? i : i.key
              ))
            }
          )
        }
      )
    }
  );
}, Mt = (e) => {
  const t = e.defaultValue ?? !1, { control: n } = E();
  return /* @__PURE__ */ r(
    V,
    {
      hasNoPaddingTop: !0,
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ r(
        N,
        {
          control: n,
          name: e.name,
          defaultValue: t,
          render: ({ field: { onChange: l, value: a } }) => /* @__PURE__ */ r(
            Ie,
            {
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              labelOff: e.labelOff,
              isChecked: e.stringify ? a === "true" : a,
              onChange: (s, o) => {
                const c = e.stringify ? s.toString() : s;
                e.onChange?.(s, o), l(c);
              }
            }
          )
        }
      )
    }
  );
}, M = ee(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(ae, { ...t, ref: n, onChange: (a, s) => e?.(s) }));
M.displayName = "TextInput";
const $t = (e) => {
  const { labelIcon: t, ...n } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: o } = oe({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ r(
    V,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: l,
      error: o.error,
      children: /* @__PURE__ */ r(
        M,
        {
          isRequired: l,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...n,
          ...s
        }
      )
    }
  );
}, $ = ee(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(ve, { ...t, ref: n, onChange: (a, s) => e?.(s) }));
$.displayName = "TextArea";
const Ht = (e) => {
  const t = !!e.rules?.required, n = e.defaultValue ?? "", { field: l, fieldState: a } = oe({
    ...e,
    defaultValue: n
  });
  return /* @__PURE__ */ r(
    V,
    {
      isRequired: t,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ r(
        $,
        {
          isRequired: t,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
};
function it(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const Gt = ({
  name: e,
  label: t,
  controller: n,
  labelIcon: l,
  ...a
}) => {
  const {
    control: s,
    formState: { errors: o }
  } = E();
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: t,
      isRequired: n.rules?.required === !0,
      error: o[e],
      labelIcon: l,
      children: /* @__PURE__ */ r(
        N,
        {
          ...n,
          name: e,
          control: s,
          render: ({ field: c }) => {
            const u = !!n.rules?.required, d = it(n.rules?.min), m = c.value ?? n.defaultValue, g = (f) => c.onChange(
              d !== void 0 ? Math.max(f, Number(d)) : f
            );
            return /* @__PURE__ */ r(
              Te,
              {
                ...a,
                id: e,
                value: m,
                validated: o[e] ? I.error : I.default,
                required: u,
                min: Number(d),
                max: Number(n.rules?.max),
                onPlus: () => g(m + 1),
                onMinus: () => g(m - 1),
                onChange: (f) => {
                  const i = Number(f.currentTarget.value);
                  g(isNaN(i) ? n.defaultValue : i);
                }
              }
            );
          }
        }
      )
    }
  );
}, ue = Y(void 0), jt = () => Q(ue), Bt = ({ children: e }) => {
  const [t, n] = A([]), l = (o) => {
    n((c) => c.filter((u) => u.id !== o));
  }, a = (o, c = _.success, u) => {
    n([
      {
        id: Math.random() * 100,
        message: o,
        variant: c,
        description: u
      },
      ...t
    ]);
  }, s = (o) => {
    a(o, _.danger);
  };
  return /* @__PURE__ */ b(ue.Provider, { value: { addAlert: a, addError: s }, children: [
    /* @__PURE__ */ r(Se, { isToast: !0, "data-testid": "alerts", children: t.map(({ id: o, variant: c, message: u, description: d }) => /* @__PURE__ */ r(
      ke,
      {
        isLiveRegion: !0,
        variant: _[c],
        variantLabel: "",
        title: u,
        actionClose: /* @__PURE__ */ r(
          xe,
          {
            title: u,
            onClose: () => l(o)
          }
        ),
        timeout: !0,
        onTimeout: () => l(o),
        children: d && /* @__PURE__ */ r("p", { children: d })
      },
      o
    )) }),
    e
  ] });
}, Ut = ({ icon: e }) => {
  const t = ct(e);
  return /* @__PURE__ */ r(t, { size: "lg", alt: e });
};
function ct(e) {
  switch (e) {
    case "github":
      return Xe;
    case "facebook":
      return Qe;
    case "gitlab":
      return Ye;
    case "google":
      return ze;
    case "linkedin":
    case "linkedin-openid-connect":
      return Ke;
    case "openshift-v3":
    case "openshift-v4":
      return We;
    case "stackoverflow":
      return Je;
    case "twitter":
      return Ue;
    case "microsoft":
      return Be;
    case "bitbucket":
      return je;
    case "instagram":
      return Ge;
    case "paypal":
      return He;
    default:
      return $e;
  }
}
const ut = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, dt = ({
  t: e,
  form: t,
  supportedLocales: n
}) => {
  const l = n.map((a) => ({
    key: a,
    value: ut(a) || ""
  }));
  return l.length ? /* @__PURE__ */ r(Ee, { ...t, children: /* @__PURE__ */ r(
    ot,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l
    }
  ) }) : null;
}, de = (e) => e?.includes("${"), me = (e) => e.substring(2, e.length - 1), O = (e, t, n) => (de(t) ? e(me(t)) : t) || n, D = (e, t) => O(e, t.displayName, t.name), mt = ["username", "firstName", "lastName", "email"], fe = (e) => e && mt.includes(e), T = (e) => `${fe(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Jt = (e) => e.replaceAll(".", "🍺"), Wt = (e) => e.replaceAll("🍺", ".");
function Kt(e, t, n) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((s) => n(de(s.toString()) ? me(s) : s))
    );
    t(T(l.field), {
      message: n(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function q({
  required: e,
  validators: t
}) {
  return e || ft(t);
}
function ft(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function zt(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: t } = e;
  return U(t) ? !0 : typeof t != "object" || t === null || !("errors" in t) || !Array.isArray(t.errors) ? !1 : t.errors.every(U);
}
function U(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const w = ({
  t: e,
  form: t,
  attribute: n,
  renderer: l,
  children: a
}) => {
  const s = n.annotations?.inputHelperTextBefore, {
    formState: { errors: o }
  } = t, c = l?.(n);
  return /* @__PURE__ */ r(
    ne,
    {
      label: D(e, n) || "",
      fieldId: n.name,
      isRequired: q(n),
      validated: B(o, T(n.name)) ? "error" : "default",
      helperTextInvalid: e(
        B(o, T(n.name))?.message
      ),
      labelIcon: s ? /* @__PURE__ */ r(ce, { helpText: s, fieldLabelId: n.name }) : void 0,
      children: c ? /* @__PURE__ */ b(se, { children: [
        a,
        c
      ] }) : a
    },
    n.name
  );
}, ht = ({
  t: e,
  form: t,
  attribute: n,
  renderer: l,
  ...a
}) => /* @__PURE__ */ r(w, { t: e, form: t, attribute: n, renderer: l, children: /* @__PURE__ */ r(
  gt,
  {
    t: e,
    form: t,
    "aria-label": D(e, n),
    name: T(n.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: D(e, n)
    }),
    ...a
  }
) }), gt = ({
  t: e,
  name: t,
  inputType: n,
  form: l,
  addButtonLabel: a,
  isDisabled: s = !1,
  defaultValue: o,
  id: c,
  ...u
}) => {
  const { register: d, setValue: m, control: g } = l, f = Me({
    name: t,
    control: g,
    defaultValue: o || ""
  }), i = S(() => Array.isArray(f) && f.length !== 0 ? f : o || [""], [f]), h = (y) => {
    R([...i.slice(0, y), ...i.slice(y + 1)]);
  }, v = () => {
    R([...i, ""]);
  }, k = (y, p) => {
    R([...i.slice(0, y), p, ...i.slice(y + 1)]);
  }, R = (y) => {
    const p = y.flatMap((F) => F);
    m(t, p, {
      shouldDirty: !0
    });
  }, ge = n.startsWith("html") ? n.substring(6) : "text";
  return Z(() => {
    d(t);
  }, [d]), /* @__PURE__ */ r("div", { id: c, children: i.map((y, p) => /* @__PURE__ */ b(te, { children: [
    /* @__PURE__ */ b(se, { children: [
      /* @__PURE__ */ r(
        ae,
        {
          "data-testid": t + p,
          onChange: (F) => k(p, F),
          name: `${t}.${p}.value`,
          value: y,
          isDisabled: s,
          type: ge,
          ...u
        }
      ),
      /* @__PURE__ */ r(
        x,
        {
          "data-testid": "remove" + p,
          variant: H.link,
          onClick: () => h(p),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: i.length === 1 || s,
          children: /* @__PURE__ */ r(Ze, {})
        }
      )
    ] }),
    p === i.length - 1 && /* @__PURE__ */ b(
      x,
      {
        variant: H.link,
        onClick: v,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !y || s,
        children: [
          /* @__PURE__ */ r(et, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, p)) });
}, J = (e) => {
  const { form: t, inputType: n, attribute: l } = e, a = q(l), s = n.startsWith("multiselect"), o = s ? Oe : Ae, c = l.validators?.options?.options || [], u = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    N,
    {
      name: T(l.name),
      control: t.control,
      defaultValue: "",
      render: ({ field: d }) => /* @__PURE__ */ r(L, { children: c.map((m) => /* @__PURE__ */ r(
        o,
        {
          id: m,
          "data-testid": m,
          label: O(e.t, u[m], m),
          value: m,
          isChecked: d.value.includes(m),
          onChange: () => {
            s ? d.value.includes(m) ? d.onChange(
              d.value.filter((g) => g !== m)
            ) : d.onChange([...d.value, m]) : d.onChange([m]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        m
      )) })
    }
  ) });
}, W = (e) => {
  const { t, form: n, inputType: l, attribute: a } = e, [s, o] = A(!1), c = q(a), u = l === "multiselect", d = (i, h) => {
    u ? h.value.includes(i) ? h.onChange(h.value.filter((v) => v !== i)) : h.onChange([...h.value, i]) : h.onChange(i);
  }, m = a.validators?.options?.options || [], g = a.annotations?.inputOptionLabels || {}, f = (i) => O(e.t, g[i], i);
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    N,
    {
      name: T(a.name),
      defaultValue: "",
      control: n.control,
      render: ({ field: i }) => /* @__PURE__ */ r(
        re,
        {
          toggleId: a.name,
          onToggle: (h) => o(h),
          isCreatable: !0,
          onCreateOption: (h) => d(h, i),
          onSelect: (h, v) => {
            const k = v.toString();
            d(k, i), Array.isArray(i.value) || o(!1);
          },
          selections: i.value ? i.value : u ? [] : t("choose"),
          variant: u ? "typeaheadmulti" : "single",
          "aria-label": t("selectOne"),
          isOpen: s,
          isDisabled: a.readOnly,
          required: c,
          children: ["", ...m].map((h) => /* @__PURE__ */ r(
            le,
            {
              selected: i.value === h,
              value: h,
              children: h ? f(h) : t("choose")
            },
            h
          ))
        }
      )
    }
  ) });
}, pt = (e) => {
  const { form: t, attribute: n } = e, l = q(n);
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    $,
    {
      id: n.name,
      "data-testid": n.name,
      ...t.register(T(n.name)),
      cols: n.annotations?.inputTypeCols,
      rows: n.annotations?.inputTypeRows,
      readOnly: n.readOnly,
      isRequired: l
    }
  ) });
}, C = (e) => {
  const { form: t, inputType: n, attribute: l } = e, a = q(l), s = n.startsWith("html") ? n.substring(6) : "text";
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    M,
    {
      id: l.name,
      "data-testid": l.name,
      type: s,
      placeholder: l.annotations?.inputTypePlaceholder,
      readOnly: l.readOnly,
      isRequired: a,
      ...t.register(T(l.name))
    }
  ) });
}, P = {
  text: C,
  textarea: pt,
  select: W,
  "select-radiobuttons": J,
  multiselect: W,
  "multiselect-checkboxes": J,
  "html5-email": C,
  "html5-tel": C,
  "html5-url": C,
  "html5-number": C,
  "html5-range": C,
  "html5-datetime-local": C,
  "html5-date": C,
  "html5-month": C,
  "html5-time": C,
  "multi-input": ht
}, Yt = ({
  t: e,
  form: t,
  userProfileMetadata: n,
  supportedLocales: l,
  hideReadOnly: a = !1,
  renderer: s
}) => {
  const o = S(() => {
    if (!n.attributes)
      return [];
    const c = a ? n.attributes.filter(({ readOnly: u }) => !u) : n.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...n.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: c.filter(
        (d) => d.group === u.name
      )
    }));
  }, [
    a,
    n.groups,
    n.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ r(
    Vt,
    {
      label: e("jumpToSection"),
      sections: o.filter((c) => c.attributes.length > 0).map(({ group: c, attributes: u }) => ({
        title: O(e, c.displayHeader, c.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-c-form", children: [
          c.displayDescription && /* @__PURE__ */ r(Ne, { className: "pf-u-pb-lg", children: O(e, c.displayDescription, "") }),
          u.map((d) => /* @__PURE__ */ r(
            bt,
            {
              t: e,
              form: t,
              supportedLocales: l,
              renderer: s,
              attribute: d
            },
            d.name
          ))
        ] })
      }))
    }
  );
}, bt = ({
  t: e,
  form: t,
  renderer: n,
  supportedLocales: l,
  attribute: a
}) => {
  const s = t.watch(
    T(a.name)
  ), o = S(() => Ct(a), [a]), c = a.multivalued || vt(s) ? P["multi-input"] : P[o];
  return a.name === "locale" ? /* @__PURE__ */ r(
    dt,
    {
      form: t,
      supportedLocales: l,
      t: e,
      attribute: a
    }
  ) : /* @__PURE__ */ r(
    c,
    {
      t: e,
      form: t,
      inputType: o,
      attribute: a,
      renderer: n
    }
  );
}, yt = "text";
function Ct(e) {
  if (fe(e.name))
    return "text";
  const t = e.annotations?.inputType;
  return It(t) ? t : yt;
}
const It = (e) => typeof e == "string" && e in P, vt = (e) => Array.isArray(e) && e.length > 1, Tt = "_title_1nyfo_2", St = {
  title: Tt
}, he = ({
  id: e,
  title: t,
  headingLevel: n = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ r(
  Ve,
  {
    headingLevel: n,
    size: l,
    className: St.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: t
  }
), kt = ({
  title: e,
  children: t,
  scrollId: n,
  className: l
}) => {
  const a = pe();
  return /* @__PURE__ */ b(qe, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ r(we, { className: "kc-form-panel__header", children: /* @__PURE__ */ r(Le, { tabIndex: 0, children: /* @__PURE__ */ r(he, { id: n, title: e }) }) }),
    /* @__PURE__ */ r(Re, { className: "kc-form-panel__body", children: t })
  ] });
}, xt = (e) => {
  const { title: t, children: n, scrollId: l, ...a } = e;
  return /* @__PURE__ */ r("section", { ...a, style: { marginTop: "var(--pf-global--spacer--lg)" }, children: /* @__PURE__ */ b(L, { children: [
    /* @__PURE__ */ r(he, { id: l, title: t }),
    n
  ] }) });
}, Ot = "_panel_cd9gh_1", At = "_sticky_cd9gh_5", K = {
  panel: Ot,
  sticky: At
}, Nt = "kc-main-content-page-container", z = (e) => e.replace(/\s+/g, "-"), Vt = ({
  label: e,
  sections: t,
  borders: n = !1,
  ...l
}) => {
  const a = S(
    () => t.filter(({ isHidden: s }) => !s),
    [t]
  );
  return /* @__PURE__ */ b(Fe, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ r(G, { md: 8, sm: 12, children: a.map(({ title: s, panel: o }) => {
      const c = z(s.toLowerCase());
      return /* @__PURE__ */ r(te, { children: n ? /* @__PURE__ */ r(
        kt,
        {
          scrollId: c,
          title: s,
          className: K.panel,
          children: o
        }
      ) : /* @__PURE__ */ r(xt, { scrollId: c, title: s, children: o }) }, s);
    }) }),
    /* @__PURE__ */ r(G, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ r(_e, { className: K.sticky, children: /* @__PURE__ */ r(
      De,
      {
        isVertical: !0,
        scrollableSelector: `#${Nt}`,
        label: e,
        offset: 100,
        children: a.map(({ title: s }) => {
          const o = z(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ r(
              Pe,
              {
                href: `#${o}`,
                "data-testid": `jump-link-${o}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
};
export {
  Bt as AlertProvider,
  Pt as ContinueCancelModal,
  kt as FormPanel,
  Et as Help,
  ce as HelpItem,
  Ut as IconMapper,
  $ as KeycloakTextArea,
  M as KeycloakTextInput,
  Gt as NumberControl,
  Vt as ScrollForm,
  ot as SelectControl,
  Mt as SwitchControl,
  Ht as TextAreaControl,
  $t as TextControl,
  Yt as UserProfileFields,
  Jt as beerify,
  tt as createNamedContext,
  Wt as debeerify,
  nt as isDefined,
  zt as isUserProfileError,
  O as label,
  Nt as mainPageContentId,
  Kt as setUserProfileServerError,
  jt as useAlerts,
  st as useHelp,
  rt as useRequiredContext,
  at as useStoredState
};
